import { CardContainer, Heading, Picture, Typography } from '@which/seatbelt'

import type { MemberBenefitOffer } from '../../../../../../generated/frontend'
import styles from './OfferBox.module.scss'

interface OfferBoxProps {
  offer: MemberBenefitOffer
  trackingData: {
    [key: string]: string
  }
}

export const OfferBox = ({ offer: { heading, text, href }, trackingData }: OfferBoxProps) => {
  return (
    <div data-testid={'offer-box-container'} className={styles.offerBoxContainer}>
      <CardContainer
        ariaLabel={text}
        id={'member-exclusive-offer'}
        arrangement={'center'}
        primaryLink={href}
        trackingData={trackingData}
      >
        <div className={styles.offerBox}>
          <Picture
            src={
              'https://media.product.which.co.uk/prod/images/original/bd63e636704a-memberbenefits.png'
            }
            alt={text}
            aspectRatioMain="one-to-one"
            height="50"
            width="50"
            className={styles.offerBoxIcon}
            imageClassName={styles.offerBoxIconImage}
            aria-hidden
          />

          {heading && <Heading headingTag="h4" headingType="small" heading={heading} />}

          {text && (
            <Typography tag="p" textStyle="sb-text-body-small-regular">
              {text}
            </Typography>
          )}
        </div>
      </CardContainer>
    </div>
  )
}
