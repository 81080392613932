import type { RecentlyViewedContent } from '../../../../generated/frontend'
import { CompactArticle, NormalArticle } from './Article'
import { CompactProduct, NormalProduct } from './Product'

type RecentlyViewedItemProps = {
  item: RecentlyViewedContent
  firstItem: boolean
}

export const RecentlyViewedItem = ({ item, firstItem }: RecentlyViewedItemProps) => {
  if (item.__typename === 'Article') {
    return firstItem ? <NormalArticle item={item} /> : <CompactArticle item={item} />
  }

  if (item.__typename === 'Product') {
    return firstItem ? <NormalProduct item={item} /> : <CompactProduct item={item} />
  }

  throw new Error('unknown type of recently viewed content')
}
