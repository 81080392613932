import type { Recommendations, RecommendedModel } from '../../../../generated/frontend'

type ModelDataType = {
  data: RecommendedModel
  hasRecommendations: boolean
}

const getModel = (recommendations: Recommendations, modelName: string): ModelDataType | null => {
  const model = recommendations.models.find((model) => model.model == modelName)

  if (!model) {
    return null
  }

  const modelData = {
    data: model,
    hasRecommendations: model?.content && model.content.length > 0,
  }

  return modelData
}

export type ModelsDataType = {
  mixedModel: ModelDataType | null
  collaborativeModel: ModelDataType | null
}

export const getModelsData = (recommendations: Recommendations): ModelsDataType => {
  const mixedModel = getModel(recommendations, 'segment/mixed/adviceOnly')
  const collaborativeModel = getModel(
    recommendations,
    'segment/content_recommendation_collaborative_filtering'
  )

  const models = {
    mixedModel,
    collaborativeModel,
  }

  return models
}
