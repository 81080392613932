import { type FunctionComponent } from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'

import type { MemberBenefitsInfo } from '../../../../generated/frontend'
import { useMemberBenefitsInfoQuery } from '../../../../generated/frontend'
import { getQueryString } from '../../../../shared/utils'
import { PersonalisedMemberBenefitsLoggedIn } from './components/LoggedIn/PersonalisedMemberBenefitsLoggedIn'
import { PersonalisedMemberBenefitsLoggedOut } from './components/LoggedOut/PersonalisedMemberBenefitsLoggedOut'
import { LoggedOutMemberBenefitsV2 } from './components/LoggedOutV2/LoggedOutMemberBenefitsV2'

const ContinuityExperiment = ({
  memberBenefitsInfo,
  creative,
  ...rest
}: {
  memberBenefitsInfo: MemberBenefitsInfo
  creative: string
}) => {
  const shouldShowNewMemberBenefits = useFeatureValue('DSP-010-continuity-from-facebook-ad', false)

  if (shouldShowNewMemberBenefits) {
    return (
      <LoggedOutMemberBenefitsV2
        creative={creative}
        calloutText={memberBenefitsInfo.calloutText}
        caption={memberBenefitsInfo.caption}
        overline={memberBenefitsInfo.overline}
        widgetLinks={memberBenefitsInfo.links}
        data-testid="logged-out-benefits-v2"
        {...rest}
      />
    )
  }

  return (
    <PersonalisedMemberBenefitsLoggedOut
      calloutText={memberBenefitsInfo.calloutText}
      caption={memberBenefitsInfo.caption}
      overline={memberBenefitsInfo.overline}
      widgetLinks={memberBenefitsInfo?.links}
      data-testid="logged-out-benefits"
      {...rest}
    />
  )
}

export const PersonalisedMemberBenefits: FunctionComponent = ({ ...rest }) => {
  const { data: { memberBenefitsInfo } = {}, loading } = useMemberBenefitsInfoQuery()
  const creative = getQueryString('creative')

  if (loading) {
    return null
  }

  if (memberBenefitsInfo === undefined) {
    return null
  }

  const isLoggedIn = memberBenefitsInfo?.isLoggedIn

  if (isLoggedIn) {
    return (
      <PersonalisedMemberBenefitsLoggedIn
        links={memberBenefitsInfo?.links}
        offer={memberBenefitsInfo?.offer}
        renewalOffer={memberBenefitsInfo?.renewalOffer}
        data-testid="logged-in-benefits"
        {...rest}
      />
    )
  }

  if (creative) {
    return <ContinuityExperiment memberBenefitsInfo={memberBenefitsInfo} creative={creative} />
  }

  return (
    <PersonalisedMemberBenefitsLoggedOut
      calloutText={memberBenefitsInfo.calloutText}
      caption={memberBenefitsInfo.caption}
      overline={memberBenefitsInfo.overline}
      widgetLinks={memberBenefitsInfo?.links}
      data-testid="logged-out-benefits"
      {...rest}
    />
  )
}
