import { useEffect, useState } from 'react'
import { getCookie } from '@which/shared'

import type { Recommendations } from '../../../../generated/frontend'
import { MultiModelsRecommendedForYou } from './components/MultiModelsRecommendedForYou'
import { RecommendedForYou } from './components/RecommendedForYou'
import { getModelsData } from './models'

interface RecommendedForYouContainerProps {
  recommendations: Recommendations
  whichId: string
}

export const RecommendedForYouContainer = ({
  recommendations,
  whichId,
}: RecommendedForYouContainerProps) => {
  const [isAnonymous, setIsAnonymous] = useState<boolean | null>(null)

  useEffect(() => {
    const userId = getCookie(document.cookie, 'master_id')
    const anonymousId = getCookie(document.cookie, 'ajs_anonymous_id')

    if (userId || anonymousId) {
      setIsAnonymous(!userId && !!anonymousId)
    }
  }, [])

  if (isAnonymous === null) {
    return null
  }

  const models = getModelsData(recommendations)

  if (models.mixedModel && models.mixedModel.hasRecommendations) {
    if (models.collaborativeModel && models.collaborativeModel.hasRecommendations) {
      return (
        <MultiModelsRecommendedForYou isAnonymous={isAnonymous} models={models} whichId={whichId} />
      )
    }
    return (
      <RecommendedForYou
        isAnonymous={isAnonymous}
        model={models.mixedModel.data}
        whichId={whichId}
      />
    )
  } else if (models.collaborativeModel && models.collaborativeModel.hasRecommendations) {
    return (
      <RecommendedForYou
        isAnonymous={isAnonymous}
        model={models.collaborativeModel.data}
        whichId={whichId}
      />
    )
  }

  return null
}
