import { Picture, Typography } from '@which/seatbelt'
import { ArrowRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import type {
  MemberBenefitLink,
  MemberBenefitWidgetLink,
} from '../../../../../../generated/frontend'
import styles from './WidgetLinkList.module.scss'

type WidgetLinkProps = {
  widgetLink: MemberBenefitWidgetLink
  showIcons?: boolean
}

type WidgetLinkListProps = {
  widgetLinks: MemberBenefitLink[] | null
  onWidgetClicked: (MemberBenefitWidgetLink) => void
  hidden: boolean
  showIcons?: boolean
}

const WidgetLink = ({ widgetLink: { image, text }, showIcons = true }: WidgetLinkProps) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.listItemLabel}>
        {showIcons && (
          <Picture
            src={image}
            aspectRatioMain="one-to-one"
            height="50"
            width="50"
            className={styles.icon}
            imageClassName={styles.iconImage}
          />
        )}
        <div className={styles.listItemTextWrapper}>
          <Typography
            tag="span"
            textStyle="sb-text-body-default-strong"
            className={styles.listItemLabelText}
          >
            {text}
          </Typography>
        </div>
      </div>
      <ArrowRightIcon />
    </div>
  )
}

export const WidgetLinkList = ({
  widgetLinks,
  onWidgetClicked,
  hidden,
  showIcons,
}: WidgetLinkListProps) => (
  <ul className={styles.list}>
    {widgetLinks?.map((widgetLink, i) => {
      return widgetLink.__typename == 'MemberBenefitWidgetLink' ? (
        <button
          key={i}
          aria-hidden={hidden}
          aria-label={widgetLink.text}
          className={styles.listItem}
          onClick={() => onWidgetClicked(widgetLinks[i])}
          tabIndex={hidden ? -1 : 0}
        >
          <WidgetLink widgetLink={widgetLink} showIcons={showIcons} />
        </button>
      ) : null
    })}
  </ul>
)
