import { Button, CardContainer } from '@which/seatbelt'
import { CrossBoldIcon } from '@which/seatbelt/src/components/Icons'
import { TypographyV2 } from '@which/seatbelt/src/components/Seatbelt2/Foundations/Typography'

import styles from './OfferCard.module.scss'

interface Props {
  size: 'compact' | 'expanded'
  onGetThisClick?: () => void
  onCloseClick?: () => void
}

export function OfferCard({ onCloseClick, onGetThisClick, size }: Props) {
  function closeButtonClick() {
    if (onCloseClick !== undefined) {
      onCloseClick()
    }
  }

  function onGetThisButtonClick() {
    if (onGetThisClick !== undefined) {
      onGetThisClick()
    }
  }

  return (
    <CardContainer id={styles.clickToRevealOffer}>
      <TypographyV2 textStyle="sb-text-heading-overline" className={styles.heading}>
        New offer
      </TypographyV2>
      {size === 'expanded' && (
        <button onClick={closeButtonClick} className={styles.closeButton} aria-label="Close">
          <CrossBoldIcon customColour="black" className={styles.crossIcon} />
        </button>
      )}
      <TypographyV2
        className={styles.title}
        textStyle={size === 'expanded' ? 'sb-text-heading-medium' : 'sb-text-heading-small'}
      >
        50% off for the next 6 months
      </TypographyV2>
      <TypographyV2 textStyle="sb-text-body-default-strong" className={styles.exclusiveText}>
        Exclusive to Full Access monthly members
      </TypographyV2>
      {size === 'expanded' && (
        <>
          <img
            className={styles.expandedImage}
            src="https://media.which.gpp.io/prod/images/original/9040e8546c98-fa707c6791868e39187ea3a77338790e.png"
            width={850}
            height={425}
            alt="Showing how Which? is available on Web, Mobile and in Print"
          />
          <TypographyV2 textStyle="sb-text-body-default-strong">
            To take up this offer:
          </TypographyV2>
          <ol className={styles.list}>
            <li>
              <TypographyV2 textStyle="sb-text-body-default-regular">Call us</TypographyV2>
              <a href="tel:02921682411" className={styles.phoneNumber}>
                <TypographyV2 textStyle="sb-text-heading-large" tag="span">
                  02921 682411
                </TypographyV2>
              </a>
              <TypographyV2 textStyle="sb-text-interface-body-small-strong">
                9am-5pm Mon-Fri, 9am-1pm Sat
              </TypographyV2>
            </li>
            <li>
              <TypographyV2 textStyle="sb-text-body-default-regular">Quote this code</TypographyV2>
              <TypographyV2 textStyle="sb-text-heading-large">FA50</TypographyV2>
            </li>
          </ol>
        </>
      )}
      {size === 'compact' && (
        <Button
          appearance="primary"
          className={styles.button}
          onClick={onGetThisButtonClick}
          data-which-id="member-benefits-offer"
          data-card-name="50 percent off for the next 6 months"
        >
          Get this offer
        </Button>
      )}
      <div className={styles.bottomBar} />
    </CardContainer>
  )
}
