import { type FunctionComponent } from 'react'
import { type AspectRatio, CardContainer, Picture, TypographyV2 } from '@which/seatbelt'
import type { ImageSource } from '@which/seatbelt/src/types'

import classNames from 'classnames'

import { TestScoreInline } from '../TestScoreInline'
import styles from './ProductReviewCard.module.scss'

export const ProductReviewCard: FunctionComponent<ProductReviewCardProps> = ({
  title,
  model,
  ariaLabel,
  imgObj,
  price,
  testScore,
  primaryLink,
  cardContainerId,
  trackingData,
  large = false,
}) => {
  const obfuscateScore = testScore === null || testScore === undefined
  return (
    <CardContainer
      ariaLabel={ariaLabel}
      arrangement="horizontal"
      primaryLink={primaryLink}
      id={cardContainerId}
      trackingData={trackingData}
    >
      <div className={large ? styles.ProductReviewCardLarge : styles.productReviewCardSmall}>
        {imgObj?.str && (
          <div className={classNames(styles.cardImg)} data-testid="product-review-card-image">
            <Picture
              alt={imgObj.imageAlt}
              aspectRatioMain="four-to-three"
              lazyLoad={imgObj.lazyLoad}
              sources={imgObj.imageSources}
              src={imgObj.str}
            />
          </div>
        )}
        <div className={styles.cardTitle}>
          <div>
            <TypographyV2 tag="h3" textStyle="sb-text-heading-small" className={styles.title}>
              {title}
            </TypographyV2>
          </div>
          <div>
            <TypographyV2 tag="span" textStyle="sb-text-body-default-regular">
              {model}
            </TypographyV2>
          </div>
          <div className={styles.productDetails}>
            <TypographyV2 tag="span" textStyle="sb-text-body-default-strong">
              {price}
            </TypographyV2>
          </div>

          <TestScoreInline
            score={testScore}
            variation={obfuscateScore ? 'obfuscated' : 'default'}
          />
        </div>
      </div>
    </CardContainer>
  )
}

type ProductReviewCardProps = {
  title: string
  model: string
  price: string
  testScore?: number
  primaryLink: string
  large?: boolean
  imgObj?: {
    imageAlt: string
    imageSources?: Sources[]
    lazyLoad?: boolean
    str: string
    aspectRatioMain?: AspectRatio
  } | null
  ariaLabel?: string
  onClickHandler?: () => void
  cardContainerId?: string
  trackingData?: Record<string, string>
}

type Sources = ImageSource
