import { ButtonLink, Image, TypographyV2 } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import { usePageProps } from '../../../../shared/usePageProps'
import styles from './FreeAccountExclusive.module.scss'

interface FreeAccountExclusiveProps {
  title: string
  description: string
  image: ImageWithSources
}

export const FreeAccountExclusive: React.FC<FreeAccountExclusiveProps> = ({
  title,
  description,
  image,
}) => {
  const { userAccessState } = usePageProps()

  if (
    !title ||
    !description ||
    !image ||
    userAccessState?.transformTypeDecision !== 'AUTHENTICATED_NO_ACCESS'
  ) {
    return null
  }

  return (
    <section className={styles.section}>
      <article className={styles.card}>
        <Image className={styles.image} {...image} />
        <div className={styles.textContent}>
          <TypographyV2 textStyle="sb-text-heading-large" tag="h2" className={styles.heading}>
            {title}
          </TypographyV2>
          <TypographyV2
            textStyle="sb-text-interface-body-small-regular"
            tag="p"
            className={styles.description}
          >
            {description}
          </TypographyV2>
          <ButtonLink href="/free-account-exclusive" appearance="primary" className={styles.button}>
            See our latest pick
          </ButtonLink>
        </div>
      </article>
    </section>
  )
}
