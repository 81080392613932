import { type ComponentPropsWithoutRef, useState } from 'react'
import { SignupButton, Typography } from '@which/seatbelt'

import type {
  MemberBenefitLink,
  MemberBenefitWidgetLink,
} from '../../../../../../generated/frontend'
import BenefitOverlay from '../BenefitOverlay/BenefitOverlay'
import { WidgetLinkList } from '../WidgetLink/WidgetLinkList'
import { ContextualPanelFooter } from './ContextualFooter/ContextualPanelFooter'
import styles from './LoggedOutMemberBenefitsV2.module.scss'

type LoggedOutMemberBenefitsV2Props = {
  widgetLinks: MemberBenefitLink[]
  creative: string
  calloutText?: string
  caption: string
  overline: string
} & ComponentPropsWithoutRef<'article'>

export const LoggedOutMemberBenefitsV2 = ({
  widgetLinks,
  creative,
  ...rest
}: LoggedOutMemberBenefitsV2Props) => {
  const [selectedBenefit, setSelectedBenefit] = useState<null | MemberBenefitWidgetLink>(null)
  const [overlayHidden, setOverlayHidden] = useState<boolean>(true)

  return (
    <article className={styles.panel} {...rest}>
      <div className={styles.overlayContainer}>
        <BenefitOverlay
          widgetLink={selectedBenefit}
          hidden={overlayHidden}
          showIcons={false}
          onBackButtonPressed={() => setOverlayHidden(true)}
        />
        <div className={styles.panelContent} aria-hidden={!overlayHidden}>
          <div className={styles.headingContainer}>
            <Typography textStyle="sb-text-heading-large">Expert product reviews</Typography>
            <Typography
              textStyle="sb-text-heading-small"
              className={styles.headingContainerSubheading}
            >
              <span className={styles.strikedPrice}>£99</span> £49.50 for the first year
            </Typography>
          </div>
          <SignupButton
            className={'sb-text-align-center'}
            buttonLink={{
              href: 'https://join.which.co.uk',
              text: 'Subscribe to Which?',
            }}
            caption={'Renews at £99 a year. Cancel any time.'}
            data-which-id="benefits-panel-button"
          />
          <div className={styles.benefitContent}>
            <WidgetLinkList
              hidden={!overlayHidden}
              showIcons={false}
              widgetLinks={widgetLinks}
              onWidgetClicked={(benefit) => {
                if (benefit.__typename == 'MemberBenefitWidgetLink') {
                  setSelectedBenefit(benefit)
                  setOverlayHidden(false)
                }
              }}
            />
          </div>
        </div>
      </div>
      <ContextualPanelFooter creative={creative} />
    </article>
  )
}
