import type { ComponentPropsWithoutRef, RefObject } from 'react'
import { useRef } from 'react'
import { Button, List, ListItem, Picture, Typography } from '@which/seatbelt'
import { ArrowLeftIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import type { MemberBenefitWidgetLink } from '../../../../../../generated/frontend'
import styles from './BenefitOverlay.module.scss'

type OverlayProps = {
  hidden: boolean
  onBackButtonPressed: () => void
  widgetLink: MemberBenefitWidgetLink | null
  showIcons?: boolean
} & ComponentPropsWithoutRef<'div'>

type OverlayContentProps = OverlayProps & {
  widgetLink: MemberBenefitWidgetLink
  backButtonRef: RefObject<HTMLButtonElement>
  showIcons?: boolean
}

const OverlayContent = ({
  onBackButtonPressed,
  widgetLink: { image, text, content },
  backButtonRef,
  hidden,
  showIcons = true,
}: OverlayContentProps) => (
  <>
    <div className={styles.overlayHeader} aria-hidden={hidden}>
      <Button
        className={styles.overlayButton}
        aria-label="Back to list"
        onClick={onBackButtonPressed}
        ref={backButtonRef}
        tabIndex={hidden ? -1 : 0}
        appearance="secondary"
      >
        <ArrowLeftIcon alt="Back to member benefits list" className={styles.overlayButtonIcon} />
      </Button>
      {showIcons && (
        <Picture
          src={image}
          alt={'Member Benefit Icon'}
          aspectRatioMain="one-to-one"
          height="50"
          width="50"
          className={styles.icon}
          imageClassName={styles.iconImage}
        />
      )}
      <Typography
        tag="span"
        textStyle="sb-text-body-default-strong"
        className={styles.benefitsRowText}
        aria-hidden={hidden}
      >
        {text}
      </Typography>
    </div>
    <List className={styles.overlayList}>
      {content.map(({ heading, subheader }, index) => (
        <ListItem icon="tick" key={index} className={styles.overlayListItem}>
          {heading && (
            <Typography textStyle="sb-text-interface-body-small-strong" tag="h3">
              {heading}
            </Typography>
          )}
          {subheader && (
            <Typography
              textStyle="sb-text-interface-body-small-regular"
              className={styles.overlayListSubheader}
              tag="p"
            >
              {subheader}
            </Typography>
          )}
        </ListItem>
      ))}
    </List>
  </>
)

const BenefitOverlay = ({
  widgetLink,
  hidden,
  onBackButtonPressed,
  showIcons,
  ...rest
}: OverlayProps) => {
  const backButtonRef = useRef<HTMLButtonElement>(null)

  const onTransitionEnd = () => {
    if (!hidden) {
      backButtonRef.current?.focus()
    }
  }

  return (
    <section
      className={classnames(styles.overlay, !hidden && styles.overlayRevealed)}
      aria-label={'member benefit info'}
      aria-hidden={hidden}
      {...rest}
      onTransitionEnd={onTransitionEnd}
    >
      {widgetLink != null && (
        <OverlayContent
          showIcons={showIcons}
          hidden={hidden}
          widgetLink={widgetLink}
          onBackButtonPressed={onBackButtonPressed}
          backButtonRef={backButtonRef}
        />
      )}
    </section>
  )
}

export default BenefitOverlay
