import { Picture } from '@which/seatbelt'

import styles from './ContextualPanelFooter.module.scss'

export type Creative = 'airfryer' | 'laptop' | 'fridge' | 'radio'

type ContextualPanelFooterProps = {
  creative: string
}

const footerImageMap: Record<Creative, string> = {
  airfryer:
    'https://media.which.gpp.io/prod/images/article_image_320px/09bb60600dc1-memberbenefitsairfryercropped.webp',
  laptop:
    'https://media.which.gpp.io/prod/images/article_image_320px/244b8d7ae606-memberbenefitslaptopcropped.webp',
  fridge:
    'https://media.which.gpp.io/prod/images/article_image_320px/55daccd3b1c2-memberbenefitsfridgecropped.webp',
  radio:
    'https://media.which.gpp.io/prod/images/article_image_320px/7abd339bb163-memberbenefitsradiocropped.webp',
}

export const ContextualPanelFooter = ({ creative }: ContextualPanelFooterProps) => {
  const imageUrl = footerImageMap[creative]
  const showImage = typeof imageUrl === 'string'

  if (!showImage) {
    console.warn(`Creative type ${creative} is not supported`)
  }

  return (
    <div className={styles.footer}>
      <div className={styles.footerRedBox}></div>

      {showImage && (
        <Picture src={imageUrl} alt={creative} imageClassName={styles.footerImage} aria-hidden />
      )}
    </div>
  )
}
