import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, Heading } from '@which/seatbelt'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './MagazineLinks.module.scss'

export const MagazineLinks: FunctionComponent<Props> = ({
  title,
  strapline,
  links
}) => (
  <section aria-labelledby="magazinelinks-header">
    <Heading
      headingType="large"
      heading={title || "Membership offers"}
      headingTag="h2"
      subHeading={strapline || "Get answers from our experts"}
    />

    <nav aria-label="Magazines links and services">
      <ul className={styles.list}>
        {links.map((magazineLink, ind) => (
          <li key={magazineLink.href}>
            <ArticleCard
              {...magazineLink}
              imageSources={magazineLink.image.sources}
              clampStrapline={false}
              className={styles.magazineLink}
              mainLinkProps={{
                'data-which-id': 'Homepage Card',
                'data-section': title,
                'data-card-name': magazineLink.title,
                'data-index': ind + 1,
              }}
            />
          </li>
        ))}
      </ul>
    </nav>
  </section>
)

type Props = {
  title: string
  strapline: string
  links: MagazineLink[]

}

type MagazineLink = {
  title: string
  strapline: string
  href: string
  image: ImageWithSources
}
