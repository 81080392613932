import { ContentCardV2 } from '@which/seatbelt'

import type { Article } from '../../../../generated/frontend'
import styles from './RecentlyViewedItem.module.scss'

type ArticleProps = {
  item: Article
}

const getValues = (item: Article) => ({
  title: item.headline || item.short_headline,
  short_title: item.short_headline || item.headline,
  description: item.standfirst || item.short_standfirst,
  short_description: item.short_standfirst || item.standfirst,
  timestamp: Number(item.updated_at || item.published_at),
})

const getTrackingData = ({ title, glide_id }) => ({
  'data-which-id': 'recently viewed card',
  'data-section': 'Recently Viewed',
  'data-card-name': title,
  'data-item-glide-id': glide_id,
})

export const NormalArticle = ({ item }: ArticleProps) => {
  const { title, short_title, description, timestamp } = getValues(item)

  return (
    <div className={styles.normalArticleCard}>
      <ContentCardV2
        ariaLabel={`Go to article: ${short_title}`}
        arrangement="horizontal"
        clampDescText
        date={new Date(timestamp)}
        description={description}
        imgObj={{
          aspectRatioMain: 'three-to-one',
          imageAlt: short_title,
          lazyLoad: true,
          str: item.promo_image_url,
        }}
        primaryLink={item.url}
        title={title}
        titleTag="h3"
        trackingData={getTrackingData({ title, glide_id: item.glide_id })}
      />
    </div>
  )
}

export const CompactArticle = ({ item }: ArticleProps) => {
  const { short_title, short_description, timestamp } = getValues(item)

  return (
    <ContentCardV2
      ariaLabel={`Go to article: ${short_title}`}
      arrangement="horizontal"
      clampDescText
      date={new Date(timestamp)}
      description={short_description}
      imgObj={{
        aspectRatioMain: 'four-to-three',
        imageAlt: short_title,
        lazyLoad: true,
        str: item.promo_image_url,
      }}
      primaryLink={item.url}
      title={short_title}
      titleTag="h3"
      trackingData={getTrackingData({ title: short_title, glide_id: item.glide_id })}
    />
  )
}
