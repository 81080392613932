import type { RefObject } from 'react'

export const accordionSectionHashes = {
  expertReview: '#review',
  comparePrices: '#compare-prices',
  testResults: '#test-results',
  techSpecs: '#technical-specifications',
  bestBuysAndDontBuys: '#best-buys-and-dont-buys',
} as const

export const accordionSectionHashStrings = Object.values(accordionSectionHashes)

type AccordionSectionHashKeys = keyof typeof accordionSectionHashes

export type AccordionSectionHashStrings = (typeof accordionSectionHashes)[AccordionSectionHashKeys]

export type AccordionRefs = Record<AccordionSectionHashStrings, RefObject<HTMLElement>>
