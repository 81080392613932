import type { MemberBenefitRenewalOffer } from '@which/transform/build/generated/backend'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { MemberBenefitOffer } from '../../../../../../generated/frontend'
import { ClickToRevealOffer } from '../ClickToRevealOffer/ClickToRevealOffer'
import { MemberExclusiveOffer } from '../MemberExclusiveOffer/MemberExclusiveOffer'

type OfferContainerProps = {
  offer?: MemberBenefitOffer
  renewalOffer?: MemberBenefitRenewalOffer
  sectionTitle: string
}

const ClickToRevealOfferExperiment = ({ offer, sectionTitle }: OfferContainerProps) => {
  const useClickToRevealRenewalOffer = useFeatureIsOn('DSP-012-click-to-reveal-offer')
  return useClickToRevealRenewalOffer ? (
    <ClickToRevealOffer />
  ) : (
    <MemberExclusiveOffer offer={offer} sectionTitle={sectionTitle} />
  )
}

export const OfferContainer = ({ offer, renewalOffer, sectionTitle }: OfferContainerProps) => {
  return renewalOffer ? (
    <ClickToRevealOfferExperiment offer={offer} sectionTitle={sectionTitle} />
  ) : (
    <MemberExclusiveOffer offer={offer} sectionTitle={sectionTitle} />
  )
}
