import { useHistory } from 'react-router-dom'
import type { BadgeVariant } from '@which/seatbelt'
import { CompactProductCard, NavigationLink, ProductCard } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import type { Product } from '../../../../generated/frontend'
import { accordionSectionHashes } from '../../../../pages/reviews/product-page/constants'
import styles from './RecentlyViewedItem.module.scss'

type HistoryType<T = unknown> = ReturnType<typeof useHistory<T>>

type ProductProps = {
  item: Product
}

const sendTrackingData = ({ url, isRetailer, item }) => {
  const { manufacturer, model, businessKey } = item

  dynamicGa4DataLayerPush({
    event: 'click_card',
    item_text: `${manufacturer?.name} | ${model}`,
    item_parent_text: 'pick up where you left off',
    item_spec: `product card${isRetailer ? ' retailer' : ''}`,
    item_group: 'recently viewed',
    item_product_id: businessKey,
    item_url: url,
    utagid: 'DSPOC285DP01',
  })
}

type callbackContent = {
  history: HistoryType
  url: string
  item: Product
}

const getCallback = ({ history, url, item }: callbackContent) => {
  return () => {
    sendTrackingData({ url, isRetailer: false, item })
    history.push(url)
  }
}

export const getRetailersCallback = ({ history, url, item }: callbackContent) => {
  return (event: { stopPropagation: () => void }) => {
    event.stopPropagation()

    const destUrl = `${url}${accordionSectionHashes.comparePrices}`

    sendTrackingData({ url: destUrl, isRetailer: true, item })
    history.push(destUrl)
  }
}

export const NormalProduct = ({ item }: ProductProps) => {
  const history = useHistory()

  return (
    <div>
      <div className={styles.normalProductCard}>
        <ProductCard
          badges={item.badges?.map((b) => b as BadgeVariant)}
          businessKey={item.businessKey}
          cardCallback={getCallback({ history, url: item.productUrl, item })}
          image={{ src: item.imageUrl }}
          manufacturer={item.manufacturer?.name}
          model={item.model}
          price={item.price?.formatted}
          productUrl={item.productUrl}
          prosConsList={{
            pros: { type: 'pros', list: item.pros },
            cons: { type: 'cons', list: item.cons },
          }}
          productScoreGauge={{
            label: 'Test Score',
            testScoreGauge: {
              overallScore: item.testScore.overallScore,
              textVariation: item.testScore.textVariation as 'obfuscated' | undefined,
            },
          }}
          retailers={item.hasRetailers}
          showCompare={false}
          slug={item.slug}
          taxonomySlug={item.category?.slug}
          viewRetailersCallback={getRetailersCallback({
            history,
            url: item.productUrl,
            item,
          })}
          saved={false}
          handleAddCompare={() => {}}
          handleRemoveCompare={() => {}}
        />
      </div>
      <NavigationLink
        className={styles.fullReviewLink}
        href={item.productUrl}
        data-which-id="recently-viewed-link"
        data-card-name={`${item.manufacturer?.name} | ${item.model}`}
      >
        Read the full review
      </NavigationLink>
    </div>
  )
}

export const CompactProduct = ({ item }: ProductProps) => {
  const history = useHistory()

  return (
    <div className={styles.compactProductCard}>
      <CompactProductCard
        badges={item.badges?.map((b) => b as BadgeVariant)}
        cardCallback={getCallback({ history, url: item.productUrl, item })}
        manufacturer={item.manufacturer?.name}
        model={item.model}
        price={item.price?.formatted}
        productScoreGauge={{
          label: 'Test Score',
          testScoreGauge: {
            overallScore: item.testScore.overallScore,
            textVariation: item.testScore.textVariation as 'obfuscated' | undefined,
          },
        }}
        productUrl={item.productUrl}
        slug={item.slug}
        retailers={item.hasRetailers}
        viewRetailersCallback={getRetailersCallback({
          history,
          url: item.productUrl,
          item,
        })}
        showCompare={false}
        saved={false}
        handleAddCompare={() => {}}
        handleRemoveCompare={() => {}}
      />
    </div>
  )
}
