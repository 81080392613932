import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { RecentlyViewed } from '../../../../generated/frontend'
import { PickUpWhereYouLeftOff } from './PickUpWhereYouLeftOff'

interface PickUpWhereYouLeftOffContainerProps {
  recentlyViewed: RecentlyViewed
}

export const PickUpWhereYouLeftOffContainer = ({
  recentlyViewed,
}: PickUpWhereYouLeftOffContainerProps) => {
  const showRecentlyViewed = useFeatureIsOn('DSP-014-homepage-recently-viewed')
  if (showRecentlyViewed) {
    const content = recentlyViewed?.content ?? []
    return <PickUpWhereYouLeftOff recentlyViewed={content} />
  }

  return null
}
