import { useState } from 'react'

import styles from './ClickToRevealOffer.module.scss'
import { OfferCard } from './OfferCard'

export function ClickToRevealOffer() {
  const [isOpen, setIsOpen] = useState(false)
  function showPopover() {
    setIsOpen(true)
  }

  function hidePopover() {
    setIsOpen(false)
  }

  return (
    <>
      <OfferCard size="compact" onGetThisClick={showPopover} />

      <div className={styles.popover} hidden={!isOpen}>
        <OfferCard size="expanded" onCloseClick={hidePopover} />
      </div>
    </>
  )
}
