import { Heading } from '@which/seatbelt'

import type { MemberBenefitOffer } from '../../../../../../generated/frontend'
import styles from '../../PersonalisedMemberBenefits.module.scss'
import { OfferBox } from './OfferBox'

export const MemberExclusiveOffer = ({
  offer,
  sectionTitle,
}: {
  sectionTitle: string
  offer?: MemberBenefitOffer
}) => {
  /* Note: although the type is not nullable, it could still get null at runtime due to the Code-Gens use of Maybe<> type */
  if (offer == undefined || offer.text === null || offer.href === null) {
    return null
  }

  return (
    <div className={styles.panelSection}>
      <Heading headingTag="h3" headingType="small" heading="Member exclusive offer:" />
      <OfferBox
        offer={offer}
        trackingData={{
          'data-which-id': 'Homepage Card',
          'data-card-name': offer.heading,
          'data-section': sectionTitle,
        }}
      />
    </div>
  )
}
