import { TypographyV2 } from '@which/seatbelt'

import type { RecentlyViewedContent } from '../../../../generated/frontend'
import { RecentlyViewedItem } from '../RecentlyViewedItem/RecentlyViewedItem'
import styles from './PickUpWhereYouLeftOff.module.scss'

interface PickUpWhereYouLeftOffProps {
  recentlyViewed: RecentlyViewedContent[]
}

export const PickUpWhereYouLeftOff = ({ recentlyViewed }: PickUpWhereYouLeftOffProps) => {
  if (recentlyViewed.length === 0) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TypographyV2 textStyle="sb-text-heading-large" className={styles.title}>
          Pick up where you left off
        </TypographyV2>
        <div className={styles.itemsGrid}>
          {recentlyViewed.map((item, idx) => (
            <RecentlyViewedItem key={getKey(item, idx)} item={item} firstItem={idx === 0} />
          ))}
        </div>
      </div>
    </div>
  )
}

export const getKey = (item: RecentlyViewedContent, idx: number) => {
  if (item.__typename === 'Article') {
    return `${item.id}-${idx}`
  }

  if (item.__typename === 'Product') {
    return item.businessKey
  }
}
