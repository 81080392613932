import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { ModelsDataType } from '../models'
import { RecommendedForYou } from './RecommendedForYou'

interface MultiModelsRecommendedForYouProps {
  isAnonymous: boolean
  models: ModelsDataType
  whichId: string
}

export const MultiModelsRecommendedForYou = ({
  isAnonymous,
  models,
  whichId,
}: MultiModelsRecommendedForYouProps) => {
  const showComponent = useFeatureIsOn('DSP-015-first-dataiku-collaborative-filtering')

  if (showComponent) {
    return (
      <RecommendedForYou
        isAnonymous={isAnonymous}
        model={models.collaborativeModel?.data}
        whichId={whichId}
      />
    )
  }

  return (
    <RecommendedForYou
      isAnonymous={isAnonymous}
      model={models.mixedModel?.data}
      whichId={whichId}
    />
  )
}
