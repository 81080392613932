import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { RecommendedModel } from '../../../../../generated/frontend'
import { RecommendedContent } from '../../RecommendedContent'

interface RecommendedForYouContentProps {
  recommendedModel: RecommendedModel
  whichId: string
}

export const RecommendedForYouContent = ({
  recommendedModel,
  whichId,
}: RecommendedForYouContentProps) => {
  const { content, model } = recommendedModel ?? {}

  if (content && model && content.length > 0) {
    return <RecommendedContent content={content} whichId={whichId} modelId={model} />
  }

  return null
}

interface AnonymousRecommendedForYou {
  recommendedModel: RecommendedModel
  whichId: string
}

export const AnonymousRecommendedForYou = ({
  recommendedModel,
  whichId,
}: AnonymousRecommendedForYou) => {
  const showComponent = useFeatureIsOn('DSP-009-anonymous-id-recommended-for-you')

  if (showComponent) {
    return <RecommendedForYouContent recommendedModel={recommendedModel} whichId={whichId} />
  }

  return null
}

interface RecommendedForYouProps {
  isAnonymous: boolean
  model?: RecommendedModel
  whichId: string
}

export const RecommendedForYou = ({ isAnonymous, model, whichId }: RecommendedForYouProps) => {
  if (!model) {
    return null
  }

  if (isAnonymous) {
    return <AnonymousRecommendedForYou recommendedModel={model} whichId={whichId} />
  }

  return <RecommendedForYouContent recommendedModel={model} whichId={whichId} />
}
